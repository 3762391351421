import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mailListUp from '@/components/StaffSelect/mailListRadioUp';
import Head from '@/components/head/index';
import { getcustomerlevel, getStructureListArr } from '@/api/PublicAPI';
import { MyLiberumList, MyLiberumreceive, MyLiberumdistribution } from '@/api/ceshi_xiugai/Sendcustomerstohighseas';
import { agentFlowerNameList, Majorlist, getLabelpeer as _getLabelpeer, FollwupIndex } from '@/api/ceshi_xiugai/whole';
import callRecord from '@/components/callRecord/index';
export default {
  components: {
    Head: Head,
    mailListUp: mailListUp,
    callRecord: callRecord
  },
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      loading: true,
      //系统教程
      total_allow: 0,
      listThematicChecked: [],
      user_arr: [{
        id: 1,
        realname: '可操作数据',
        num: 0
      }],
      flower_id: '',
      //学员来源id
      flower_Array: [],
      //学员来源
      title: '部门公海客户池',
      value1: '',
      get_type: 6,
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      customer_id: '',
      customer: [],
      //学员级别
      customer_name: '',
      //	微信/手机号/学员称呼
      wxnum_mobile_qq: '',
      tableData: [],
      Alltableoptions: [],
      staffSelectVisible: false,
      staffResult: [],
      staffDisableArr: [],
      condition: [],
      radio: false,
      tempId: '',
      editForm: {
        type: 0,
        customer_levels: '',
        //学员级别（传id）
        source_client_id: '',
        //花名（学员来源） 传id
        create_stime: '',
        //创建开始时间
        create_etime: '',
        //创建结束时间
        ittnzy: '',
        //意向专业（1,2,3）
        next_follow_stime: '',
        //下次回访日期开始时间
        next_follow_etime: '',
        //下次回访日期结束时间
        start_lib_time: '',
        end_lib_time: '',
        create_realname: '',
        //创建人
        structure_ids: '',
        //原始部门 （1,2,3）
        labelpeers: '',
        //客户标签(1,2,3)
        notes: '',
        //备注
        create_time_order: '',
        //创建日期排序 1正序 2倒序 0不排序
        follow_time_order: '',
        //最后跟进日期排序 1正序 2倒序 0不
        regression_time_order: '',
        //公海日期日期排序 1正序 2倒序 0不
        next_time_order: '',
        //下次回访日期排序 1正序 2倒序 0不排
        now_follow_num_order: '',
        //跟进次数排序 1正序 2倒序 0不排
        customer_name: '',
        //手机号 微信 qq号 用户名
        wxnum_mobile_qq: '',
        paginate: '',
        page: '',
        mobile_address: '',
        is_allow: '',
        start_follow_time: '',
        end_follow_time: ''
      },
      finalFollowshow: false,
      seaShow: false,
      attributionshow: false,
      nexttimeshow: false,
      //下次回访日期
      lasttimeshow: false,
      //最后跟进日期
      createtimeshow: false,
      //创建日期
      ittnzyshow: false,
      //意向专业
      createnameshow: false,
      //创建人
      remarksshow: false,
      //备注
      labelpeersshow: false,
      //客户标签
      structure_idshow: false,
      //组织部门

      attribution: '',
      createtime: '',
      //创建日期
      showittnzy: '',
      //意向专业
      Intendeds: [],
      //意向专业
      nexttime: '',
      //下次回访日期
      finalFollow: '',
      //最后跟进日期
      finalFollowNew: '',
      //未跟进时长
      seaDate: '',
      //公海日期
      create_realname: '',
      //创建人
      structure_id: '',
      //组织部门
      bumenoptions: [],
      labelpeers: '',
      //客户标签
      labelpeersEs: [],
      remarks: '',
      //备注
      followUp: false,
      //跟进弹窗
      activities: [],
      reverse: true,
      dialogTableVisible: false,
      callCid: '',
      cusName: '',
      cusPhone: '',
      sNum: '',
      eNum: '',
      callNumShow: false,
      call_num_order: '0',
      nowTimeL: '',
      timeArry: []
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.Getcustomer();
      this.majorDedMaj(); //意向专业
      this.getStructure(); //组织部门
      this.getLabelpeer();
    } else {}
    this.nowTimeL = Number(new Date().getTime());
    this.timeArry = [{
      id: 0,
      title: '3个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 90, this.nowTimeL]
    }, {
      id: 1,
      title: '4个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 120, this.nowTimeL]
    }, {
      id: 2,
      title: '6个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 180, this.nowTimeL]
    }, {
      id: 3,
      title: '8个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 240, this.nowTimeL]
    }, {
      id: 4,
      title: '10个月未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 300, this.nowTimeL]
    },
    // {
    //     id: 5,
    //     title: '12个月未跟进',
    //     value: [this.nowTimeL - 3600 * 1000 * 24 * 365, this.nowTimeL]
    // },
    {
      id: 6,
      title: '12个月以上未跟进',
      value: [this.nowTimeL - 3600 * 1000 * 24 * 365, this.nowTimeL - 3600 * 1000 * 24 * 365]
    }];
  },
  created: function created() {
    if (this.course != 1) {
      this.GetList();
      this.flowerArray();
    }
  },
  methods: {
    //学员级别list
    Getcustomer: function Getcustomer() {
      var _this2 = this;
      getcustomerlevel().then(function (res) {
        _this2.customer = res.data;
      });
    },
    flowerArray: function flowerArray() {
      var _this3 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this3.flower_Array = res.data;
      });
    },
    getStructure: function getStructure() {
      var _this4 = this;
      //组织部门
      getStructureListArr().then(function (respomse) {
        _this4.bumenoptions = respomse.data;
      });
    },
    majorDedMaj: function majorDedMaj() {
      var _this5 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        _this5.Intendeds = res.data.major_list;
      });
    },
    // 客户标签
    getLabelpeer: function getLabelpeer() {
      var _this6 = this;
      _getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (itemEs) {
          itemEs.id = String(itemEs.id);
        });
        _this6.labelpeersEs = res.data;
        // this.customer = res.data;
      });
    },
    //部分复选框禁用
    selectable: function selectable(row, index) {
      if (row.allow_status == 0) {
        return false;
      } else {
        return true;
      }
    },
    viewRecord: function viewRecord(id, name, phone) {
      this.dialogTableVisible = true;
      this.callCid = id;
      this.cusName = name;
      this.cusPhone = phone;
    },
    callNums: function callNums() {
      //拨打次数筛选
      this.query.page = 1;
      if (this.sNum == '' && this.eNum == '') {
        this.sNum = '';
        this.eNum = '';
        this.GetList();
      } else if (this.sNum == '' || this.eNum == '') {
        this.$message({
          message: '请输入完整次数段',
          type: 'warning'
        });
      } else if (Number(this.sNum) > Number(this.eNum)) {
        this.$message({
          message: '请输入正确次数范围',
          type: 'warning'
        });
      } else {
        this.GetList();
      }
    },
    GetList: function GetList() {
      var _this7 = this;
      var _this = this.editForm;
      // this.editForm.page = this.query.page;
      console.log('[aaaaaaa]', _this);
      this.loading = true;
      MyLiberumList({
        type: _this.type,
        customer_levels: _this.customer_levels,
        //学员级别（传id）
        source_client_id: _this.source_client_id,
        //花名（学员来源） 传id
        create_stime: _this.create_stime,
        //创建开始时间
        create_etime: _this.create_etime,
        //创建结束时间
        ittnzy: _this.ittnzy,
        //意向专业（1,2,3）
        next_follow_stime: _this.next_follow_stime,
        //下次回访日期开始时间
        next_follow_etime: _this.next_follow_etime,
        //下次回访日期结束时间
        start_lib_time: _this.start_lib_time,
        end_lib_time: _this.end_lib_time,
        create_realname: _this.create_realname,
        //创建人
        structure_ids: _this.structure_ids,
        //原始部门 （1,2,3）
        labelpeers: _this.labelpeers,
        //客户标签(1,2,3)
        notes: _this.notes,
        //备注
        create_time_order: _this.create_time_order,
        //创建日期排序 1正序 2倒序 0不排序
        follow_time_order: _this.follow_time_order,
        //最后跟进日期排序 1正序 2倒序 0不
        regression_time_order: _this.regression_time_order,
        //公海日期日期排序 1正序 2倒序 0不
        next_time_order: _this.next_time_order,
        //下次回访日期排序 1正序 2倒序 0不排
        customer_name: this.customer_name,
        //手机号 微信 qq号 用户名
        wxnum_mobile_qq: this.wxnum_mobile_qq,
        paginate: this.query.pagesize,
        page: this.query.page,
        mobile_address: _this.mobile_address,
        is_allow: _this.is_allow,
        calls_num: this.sNum,
        calle_num: this.eNum,
        call_num_order: this.call_num_order,
        // 未跟进时长
        end_follow_time: _this.end_follow_time,
        start_follow_time: _this.start_follow_time,
        now_follow_num_order: _this.now_follow_num_order,
        // 进入公海日期筛选
        start_regression_time: _this.start_regression_time,
        end_regression_time: _this.end_regression_time
      }).then(function (res) {
        _this7.loading = false;
        _this7.tableData = res.data.data;
        _this7.query.total = res.data.total;
        _this7.total_allow = res.data.total_allow;
      });
    },
    radioFix: function radioFix() {
      if (this.listThematicChecked.length > 1) {
        this.listThematicChecked.splice(0, 1);
      }
      this.editForm.is_allow = String(this.listThematicChecked);
      this.query.page = 1;
      this.GetList();
    },
    //学员级别查询
    customerhand: function customerhand(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 学员级别的最后一位ID
      this.editForm.customer_levels = share;
      this.query.page = 1;
      this.GetList();
    },
    //学员来源查询
    flowerArrayEs: function flowerArrayEs() {
      var _this8 = this;
      this.query.page = 1;
      if (this.flower_id !== '') {
        this.editForm.source_client_id = this.flower_id;
        this.$nextTick(function () {
          _this8.GetList();
        });
      } else {
        this.editForm.source_client_id = '';
        this.$nextTick(function () {
          _this8.GetList();
        });
      }
    },
    //查询
    handleFilter: function handleFilter() {
      var _this9 = this;
      this.query.page = 1;
      this.loading = true;
      MyLiberumList({
        paginate: this.query.pagesize,
        page: this.query.page,
        customer_name: this.customer_name,
        wxnum_mobile_qq: this.wxnum_mobile_qq,
        type: 0
      }).then(function (res) {
        _this9.tableData = res.data.data;
        _this9.query.total = res.data.total;
        _this9.loading = false;
      });
    },
    //查询
    wxnumMobileQq: function wxnumMobileQq() {
      var _this10 = this;
      this.query.page = 1;
      this.loading = true;
      MyLiberumList({
        paginate: this.query.pagesize,
        page: this.query.page,
        wxnum_mobile_qq: this.wxnum_mobile_qq,
        customer_name: this.customer_name,
        type: 0
      }).then(function (res) {
        _this10.tableData = res.data.data;
        _this10.query.total = res.data.total;
        _this10.loading = false;
      });
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      var _this11 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this11.GetList();
      });
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this12 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this12.GetList();
      });
    },
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      this.tempId = this.Alltableoptions.map(function (item) {
        return item.customer_data_id;
      }).join(',');
    },
    //领取
    Receive: function Receive() {
      var _this13 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认领取该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          MyLiberumreceive({
            customer_data_ids: String(_this13.tempId)
          }).then(function (res) {
            _this13.$notify({
              title: '提示',
              message: res.data.toString(),
              type: 'success',
              duration: 3000
            });
            _this13.$nextTick(function () {
              //获取列表方法
              _this13.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选择领取项');
      }
    },
    //分配
    Distribution: function Distribution() {
      if (this.Alltableoptions.length !== 0) {
        this.staffSelectVisible = true;
        this.radio = true;
      } else {
        this.$message('请选中移交项');
      }
    },
    staffSelectConfirm: function staffSelectConfirm(resultNameArr, result) {
      var _this14 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认分配该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            customer_data_ids: String(_this14.tempId),
            user_id: result.toString()
          };
          MyLiberumdistribution(para).then(function (res) {
            _this14.$notify({
              title: '提示',
              message: res.data.toString(),
              type: 'success',
              duration: 3000
            });
            _this14.$nextTick(function () {
              //获取列表方法
              _this14.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中分配项');
      }
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '下次回访日期') {
        this.nexttimeshow = true;
      } else if (item === '创建日期') {
        this.createtimeshow = true;
      } else if (item === '意向专业') {
        this.ittnzyshow = true;
      } else if (item === '创建人') {
        this.createnameshow = true;
      } else if (item === '备注') {
        this.remarksshow = true;
      } else if (item === '组织部门') {
        this.structure_idshow = true;
      } else if (item == '客户标签') {
        this.labelpeersshow = true;
      } else if (item == '最后跟进日期') {
        this.finalFollowshow = true;
      } else if (item == '进入公海日期') {
        this.seaShow = true;
      } else if (item == '归属地') {
        this.attributionshow = true;
      } else if (item === '拨打次数') {
        this.callNumShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '下次回访日期') {
        this.nexttimeshow = false;
        this.editForm.next_follow_stime = '';
        this.editForm.next_follow_etime = '';
        this.nexttime = '';
      } else if (item === '最后跟进日期') {
        this.finalFollowshow = false;
        // this.editForm.start_follow_time = '';
        // this.editForm.end_follow_time = '';
        this.finalFollow = '';
        this.FinalFollowUpEs();
        return;
      } else if (item === '进入公海日期') {
        this.seaShow = false;
        this.editForm.start_regression_time = '';
        this.editForm.end_regression_time = '';
        this.seaDate = '';
      } else if (item === '创建日期') {
        this.createtimeshow = false;
        this.editForm.create_stime = '';
        this.editForm.create_etime = '';
        this.createtime = '';
      } else if (item === '意向专业') {
        this.ittnzyshow = false;
        this.showittnzy = '';
        this.editForm.ittnzy = '';
      } else if (item === '创建人') {
        this.createnameshow = false;
        this.editForm.create_realname = '';
      } else if (item === '备注') {
        this.remarksshow = false;
        this.remarks = '';
        this.editForm.notes = '';
      } else if (item === '组织部门') {
        this.structure_idshow = false;
        this.editForm.structure_ids = '';
        this.structure_ids = '';
      } else if (item === '客户标签') {
        this.labelpeersshow = false;
        this.labelpeers = '';
        this.editForm.labelpeers = '';
      } else if (item === '归属地') {
        this.attributionshow = false;
        this.attribution = '';
        this.editForm.mobile_address = '';
      } else if (item === '拨打次数') {
        this.callNumShow = false;
        this.sNum = '';
        this.eNum = '';
      }
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //创建人
    createname: function createname() {
      this.editForm.create_realname = this.create_realname;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //创建时间
    creatDateTime: function creatDateTime() {
      this.query.page = 1;
      if (this.createtime == null) {
        this.editForm.create_stime = '';
        this.editForm.create_etime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.create_stime = this.createtime[0].toString().substring(0, 10);
        this.editForm.create_etime = this.createtime[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //回访时间选择
    nextDateTime: function nextDateTime(item) {
      this.query.page = 1;
      if (this.nexttime == null) {
        this.editForm.next_follow_stime = '';
        this.editForm.next_follow_etime = '';
        this.GetList(this.editForm.page, this.editForm.pagesize);
      } else {
        this.editForm.next_follow_stime = this.nexttime[0].toString().substring(0, 10);
        this.editForm.next_follow_etime = this.nexttime[1].toString().substring(0, 10);
        this.GetList(this.editForm.page, this.editForm.pagesize);
      }
    },
    //最后跟进日期筛选
    FinalFollowUpEs: function FinalFollowUpEs() {
      this.query.page = 1;
      // 未跟进时长 和 最后跟进日期条件互斥
      if (this.finalFollowNew == '' || this.finalFollowNew == null) {
        // 如果未跟进时长为空 则只看最后跟进日期
        if (this.finalFollow == null || this.finalFollow == '') {
          this.editForm.start_follow_time = '';
          this.editForm.end_follow_time = '';
        } else {
          this.editForm.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
          this.editForm.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        }
      } else {
        // 如果未跟进时长有值 则只看未跟进时长
        this.editForm.start_follow_time = '';
        // this.editForm.start_follow_time = this.finalFollowNew[0].toString().substring(0, 10);
        this.editForm.end_follow_time = this.finalFollowNew[0].toString().substring(0, 10);
      }
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //进入公海日期筛选
    seaDateEs: function seaDateEs() {
      this.query.page = 1;
      if (this.seaDate == null) {
        this.editForm.start_regression_time = '';
        this.editForm.end_regression_time = '';
        if (this.course != 1) {
          this.GetList(this.editForm.page, this.editForm.pagesize);
        } else {
          this.Buried.search_create_time = this.editForm.start_regression_time + ',' + this.editForm.end_regression_time;
          this.statisticalTable(this.query.page, this.query.pagesize);
        }
      } else {
        this.editForm.start_regression_time = this.seaDate[0].toString().substring(0, 10);
        this.editForm.end_regression_time = this.seaDate[1].toString().substring(0, 10);
        if (this.course != 1) {
          this.GetList(this.editForm.page, this.editForm.pagesize);
        } else {
          this.Buried.search_create_time = this.editForm.start_regression_time + ',' + this.editForm.end_regression_time;
          this.statisticalTable(this.query.page, this.query.pagesize);
        }
      }
    },
    //意向专业
    shouwittnzyclick: function shouwittnzyclick() {
      this.editForm.ittnzy = this.showittnzy;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //组织部门
    structureclick: function structureclick(val) {
      this.query.page = 1;
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.editForm.structure_ids = adad;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    customerLabel: function customerLabel(item) {
      //客户标签筛选
      this.editForm.labelpeers = item;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    //备注
    remarksClick: function remarksClick() {
      this.editForm.notes = this.remarks;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    attributionClick: function attributionClick() {
      this.editForm.mobile_address = this.attribution;
      this.query.page = 1;
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    sortEs: function sortEs(column, prop, order) {
      this.query.page = 1;
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'create_time') {
        //创建日期
        if (column.order == 'ascending') {
          this.editForm.create_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.create_time_order = '2';
        } else {
          this.editForm.create_time_order = '0';
        }
        this.editForm.follow_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.call_num_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'follw_time') {
        //最后跟进日期
        if (column.order == 'ascending') {
          this.editForm.follow_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.follow_time_order = '2';
        } else {
          this.editForm.follow_time_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.call_num_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'regression_time') {
        //进入公海日期
        if (column.order == 'ascending') {
          this.editForm.regression_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.regression_time_order = '2';
        } else {
          this.editForm.regression_time_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.follow_time_order = '0';
        this.call_num_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'next_time') {
        //下次回访日期
        if (column.order == 'ascending') {
          this.editForm.next_time_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.next_time_order = '2';
        } else {
          this.editForm.next_time_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.follow_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.call_num_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'all_call_num') {
        //拨打次数
        if (column.order == 'ascending') {
          this.call_num_order = '1';
        } else if (column.order == 'descending') {
          this.call_num_order = '2';
        } else {
          this.call_num_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.follow_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.editForm.next_time_order = '0';
        this.editForm.now_follow_num_order = '0';
      } else if (column.prop == 'now_follow_num') {
        //跟进次数
        if (column.order == 'ascending') {
          this.editForm.now_follow_num_order = '1';
        } else if (column.order == 'descending') {
          this.editForm.now_follow_num_order = '2';
        } else {
          this.editForm.now_follow_num_order = '0';
        }
        this.editForm.create_time_order = '0';
        this.editForm.follow_time_order = '0';
        this.editForm.regression_time_order = '0';
        this.call_num_order = '0';
        this.editForm.next_time_order = '0';
      }
      this.GetList(this.editForm.page, this.editForm.pagesize);
    },
    followUpCustomers: function followUpCustomers(id) {
      var _this15 = this;
      //跟进弹窗
      // this.followUpEs.type = 1;
      this.followUp = true;
      // this.followUpEs.name = id.cname;
      // this.followUpId = id.customer_id;
      // this.followUpIdES = id.customer_data_id;
      FollwupIndex({
        id: id.customer_id,
        customer_data_id: id.customer_data_id,
        is_all: 1,
        is_realname: 0
      }).then(function (res) {
        _this15.activities = res.data;
      });
    }
  }
};